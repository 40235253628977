.checkbox_discount{
    position: absolute;
}
/* لتوسيع العرض بالكامل */
.form-group select {
    width: 100%;
  }
  
  /* تنسيق القائمة */
  .form-select {
    width: 100%;
    direction: ltr; /* الاتجاه الافتراضي للإنجليزية */
  }
  
  /* ضبط الاتجاه عند استخدام اللغة العربية */
  :lang(ar) .form-select {
    direction: rtl;
  }
  
  /* تنسيق عناصر الخيار */
  .coupon-option {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 5px;
  }
  
  /* تنسيق النصوص */
  .coupon-name, .coupon-discount {
    font-size: 14px;
    padding: 0 10px;
  }
  