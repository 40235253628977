* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}

:root {
  --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
  --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
  --backgroundColor: #00ADEEFF;
  --dropdownBorderColor: rgb(242, 240, 240);
  --color1: #5356FF;
  --color2: #378CE7;
  --color3: #67C6E3;
  --color4: #DFF5FF;
}
.Sidebar {
  width: 17%;
  /* min-height: 100%; */
  background-color: var(--color2);
  position: fixed;
  top: 70px;
  /* left: 0; */
  padding: 1%;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  height: calc(100% - 70px);
  z-index: 4;
}

html[dir="rtl"] .Sidebar {
  left: auto;
  right: 0;
}
html[dir="ltr"] .Sidebar {
  left: 0;
  right: auto;
}

.Sidebar ul {
  margin: 0;
  padding: 0;
  height: 50%;
}
.Sidebar img {
  width: 60%;
  margin: auto;
  text-align: center;
  padding: 5px 0;
}

.Sidebar ul li {
  display: flex;
  align-items: center;
  padding-bottom: 1%;
  color: #fff;
  list-style: none;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.Sidebar ul li i {
  padding: 5px 10px 5px 8px;
  font-size: 20px;
}

.Sidebar ul li .link {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  padding: 2px 3px;
}

.Sidebar ul li .link:hover {
  background-color: rgba(225, 225, 228, 0.6);
  border-radius: 7px;
}

.active {
  background-color: rgba(225, 225, 228, 0.6);
  border-radius: 10px; /* توحيد البوردار رديوس */
  border: 1px solid rgba(225, 225, 228, 0.8); /* تحديد حدود ناعمة وشفافة */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* تأثير خفيف لدمج أفضل مع الشاشة */
}

.Sidebar button {
  width: 90%;
  padding: 10px 15px;
  color: var(--backgroundColor);
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  background-color: white;
  margin-top: auto;
  transition: background-color 0.3s ease;
  /* position: absolute; */
  /* bottom: 2%; */
  margin: auto;
  left: 10%;
}

.Sidebar button:hover {
  background-color: #f0f0f0;
}

.Sidebar button i {
  margin-left: 3px;
}
