* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}

:root {
  --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
  --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
  --backgroundColor: #00ADEEFF;
  --dropdownBorderColor: rgb(242, 240, 240);
  --color1: #5356FF;
  --color2: #378CE7;
  --color3: #67C6E3;
  --color4: #DFF5FF;
}

.Statistics {
  width: 83%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color4);
  padding-bottom: 20px;
}
html[dir="rtl"] .Statistics {
  right: auto;  
  left: 0;   
}

.Statistics .name_header {
  height: 70px;
  line-height: 70px;
  color: white;
  display: flex;
  align-items: center;
  background-image: url('./images/portfolio-2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 4px 4px 0 rgba(51, 51, 51, 0.2), 0 3px 4px 0 rgba(51, 51, 51, 0.19);
}

.Statistics .name_header p {
  margin: 0;
  padding: 0 1%;
}

.Statistics h4 {
  color: var(--color1);
}
.Statistics .name_header i {
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background-color: aqua;
  display: block;
  text-align: center;
  line-height: 40px;
}

.Statistics .totals div,
.Statistics .customer div div {
  border: 1px solid #777;
  padding: 2%;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 10px;
}

.Statistics .totals div p,
.Statistics .customer div p {
  color: #777;
}

.netProfits{
  min-height: 100px;
}
.Statistics .totals div p span,
.Statistics .customer div div span {
  color: #2ecf20;
}

.gross div {
  min-height: 150px;
}

.gross div span {
  color: #2ecf20;
}

.download button{
  background-image: var(--backgroundGradientMain);
  color: white;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 5px;
}
.amla{
  background-color: white;
  border-radius: 5px;
  padding: 5px;
}
.amla .totle p span{
  font-size: 20px;
  color: #2ecf20;
}
.amla .adrs{
  font-size: 20px;
  height: 200px;
  background-color: rgb(233, 231, 231);
  border-radius: 10px;
  overflow: auto;
  padding: 5px;
}