* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
    --backgroundColor: #00ADEEFF;
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;

  }
  
  .AddProduct{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    padding-bottom: 10px;
    background-color: var(--color4);
  }
  html[dir="rtl"] .AddProduct {
    right: auto;  
    left: 0;   
  }
  .AddProduct .name_header{
    height: 70px;
    line-height: 70px;
    color: white;
    background-image: var(--backgroundGradientBorder);

}
.AddProduct .name_header i{
    padding: 0 2%;
}

.icon{
  height: 70px;
  line-height: 70px;
  color: white;
  width: 100%;
}
.icon .i{
  padding: 0 2%;
  border-radius: 50%;
  cursor: pointer;
}

  .Add_img .add{
    background-color: white;
    height: 300px;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    margin: auto;
    padding: 2%;
    border: 2px solid var(--backgroundColor);
    display: block;
  }
  .Add_img .add .upload-placeholder{
    text-align: center;
    margin: auto;
    color: #777;
  }
  .Add_img .add .upload-placeholder i{
    text-align: center;
    margin: auto;
    color: #777;
    font-size: 50px;
  }
  .Add_img .add .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    display: block; 
}
  .Add_img .title2{
    margin-top: 5%;
    margin-bottom: 0%;
    padding: 0;
}
.single{
  width: 100%;
  height: 100%;
}
  .Add_img .add .imgs{
    height: 120px;
    object-fit: cover;
    border-radius: 10px;
  }


  .div2,
   .div1{
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
  }
    input{
    outline: none;
    padding: 2%;
    height: 40px;
    line-height: 40px;
    border: 0;
    border-radius: 5px;
  }
  
  .check{
    margin: 5% 0;
    width: 100%;

  }
   button{
    padding: 1%;
    border-radius: 5px;
    text-align: center;
    display: block;
    border: 0;
    color: white;
    margin: auto;
    background-color: var(--color1);
  }


  .box_AddCompany .header{
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0px 7px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    background-color: white;
    

}
.box_AddCompany .header i{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    transform: rotate(90deg);

}

.box_AddCompany .header span{
    z-index: 2;
    display: block;
    color: black;
}

.category_container{
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  margin-top: 30px;
}
.category-item .category_content{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #777;
  padding: 10px 0;
}
.category-item .subcategory_content:hover,
.category-item .category_content:hover{
  background-color: rgb(205, 204, 204);
  cursor: pointer;
  p{
    color: var(--backgroundColor);

  }
}
.category-item .category_content p:hover{
  color: var(--backgroundColor);
}
.category-item .subcategory_content,
.category-item .category_content{
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border: 0;
  background-color: white;
}
.category-item .subcategory_content{
  width: 80%;
  background-color: rgb(234, 232, 232);
  margin: 5px auto;
  border-radius: 3px;
  padding: 2px 5px;

}
.category-item .category_content img{
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-inline-end: 5px;
}
.category-item .category_content p{
  border: 0;
}
.category-item .subcategory_content img{
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-inline-end: 5px;
}
.category-item .subcategory_content p{
  border: 0;
  font-size: 13px;
}
.error,
.error{
    z-index: 2;
    font-size: 12px;
    font-weight: 600;
    color: red;
    float: left;
    margin-bottom: 30px;
    padding: 0;
    margin-bottom: -9px;
    text-align: start;
    display: flex;
    justify-content: end;
  }

  .popup {
    position: fixed;
    top: -100px; /* تبدأ البوب أب فوق */
    left: 0;
    right: 0;
    background-color: #ff5555; /* لون الخلفية */
    color: white;
    text-align: center;
    padding: 20px;
    font-size: 18px;
    transition: top 0.5s ease-in-out;
    z-index: 1000; /* عشان يكون فوق الكل */
}

.popup.show {
    top: 0; /* تنزل البوب أب لتظهر */
    transition: top 0.5s ease-in-out;

}


.size-selection{
  background-color: #777;
  /* display: flex; */
  display: inline-block;
  width: 100%;
}
.size-selection .selection {
  background-color: rgb(227, 223, 223);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2px 7px;

}
.size-selection .selection input{
  
  cursor: pointer;

}
.size-selection .selection:hover {
  background-color: var(--color3);
  color: white;


}
.size-selection .selection p{
  margin: 0;

}


.size-selection-text{
  background-color: white;
  margin-top: 5px;
  border-radius: 5px;
  min-height: 40px;
  line-height: 40px;
  padding: 0 2px;
}

.slect_category{
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  padding: 0 5px;
  border-radius: 5px;
  background-color: white;
}
.oox_container i,
.slect_category i{
  transform: rotate(90deg);
}


.oox_container .box_header{
  border-bottom: 1px solid #777;
  display: flex;
  height: 40px;
  line-height: 40px;
  justify-content: space-between;
  padding: 0 8px;
  background-color: white;
  cursor: pointer;
  
}
.oox_container .box_header:hover{
  background-color: rgb(205, 204, 204);
}

.box_button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

}
.box_button button{
  width: 40%;
  height: 30px;
  line-height: 30px;
  padding: 0;
  background-color: var(--color1);
}
.input-group{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.input-group input{
  width: 40%;
}
.input-group select{
  width: 40%;
  height: 40px;
  line-height: 40px;
}


.addTimePriceField{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.addTimePriceField .addTime{
  width: 30%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--color1);
  color: white;

}

.input-group{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  margin: 3px 0;
}
.addSize{
  background-color: var(--color1);
  width: 30%;
  margin: 0;
  padding: 0;
  text-align: center;
  border-radius: 5px;
  color: white;
  padding: 5px 0;

}
.addSizeField{
  background-color: var(--color1);
  width: 30%;
  margin: 0;
  padding: 0;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  margin: 15px auto;
  padding: 5px 0;
}

.popup-message {
  position: fixed;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);
  background-image: var(--backgroundGradientMain);
  color: white;
  padding: 20px 40px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  animation: fadeInOut 3.5s ease-in-out forwards;
  text-align: center; 
}

/* محتوى الرسالة */
.popup-conten {
  display: flex;
  align-items: center;
  /* gap: 15px;  */
}

.popup-conten .fa-check-circle {
  font-size: 24px;
  color: #00ff88; 
}

/* تأثير fade-in-out */
@keyframes fadeInOut {
  0% {
      opacity: 0;
      transform: translate(-50%, -60%);
  }
  10% {
      opacity: 1;
      transform: translate(-50%, -50%);
  }
  90% {
      opacity: 1;
      transform: translate(-50%, -50%);
  }
  100% {
      opacity: 0;
      transform: translate(-50%, -40%);
  }
}












