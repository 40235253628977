* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
    --backgroundColor: #00ADEEFF;
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;

  }
.Transactions{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    height: 100%;
  }
  html[dir="rtl"] .Transactions {
    right: auto;  
    left: 0;   
  }
.Transactions .name_header{
    height: 70px;
    line-height: 70px;
    color: white;
    background-image: var(--backgroundGradientBorder);

}

.Transactions .name_header i{
    padding: 0 2%;
}
.Transactions .Pending{
    padding: 0 2%;
    background-color: white;
    height: 50px;
    line-height: 50px;
    display: flex;
    margin: 1% auto;
    border-radius: 10px;
}
.Transactions .Pending p{
    padding: 0 2%;
    margin: 0;
}
.Transactions .Pending p:hover{
    color: var(--backgroundColor);
    cursor: pointer;
}
.Transactions .sarsh{
    color: var(--backgroundColor);
    cursor: pointer;

}
.Transactions .sarsh input{
    width: 40%;
    outline: none;
    padding: 0.8% 1%;
    border-radius: 10px;
    margin: 0;

}
.Transactions .Recent{
    padding:2% 0;

}
.Transactions .Orders{
    padding:1% 2%;
    background-color: white;
    margin: 1% 0;
    border-radius: 10px;
    height: 100%;
}
.Transactions .Orders .Orders_border{
    border-bottom: 1px solid #777;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    text-align: center;

}
.Transactions .Orders .Orders_border p{
    border-bottom: 1px solid #777;
    height: 40px;
    line-height: 40px;
    width: 14%;
    margin: 0;
    padding: 0;
    text-align: center;

}
.Transactions .Orders .div_Transactions{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #777;
}
.Transactions .Orders .div_Transactions p{
    height: 40px;
    line-height: 40px;
    width: 15%;
    margin: 0;
    padding: 0;
    text-align: center;

}

.Transactions .Orders .Orders_border2{
    border-bottom: 1px solid #777;
    height: 40px;
    line-height: 40px;
    background-color: rgba(0, 174, 238, 0.395);
}
.Transactions .Orders .Orders_border3{
    border-top: 1px solid #777;
    height: 70px;
    line-height: 70px;
    background-color: rgba(0, 174, 238, 0.227);
}

.ProductsWrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
    margin: 0;
    padding: 0;
}

.ProductsWrapper.active {
    max-height: 500px; 
    transition: max-height 0.5s ease-in, padding 0.5s ease-in;
    padding: 10px 15px; 
}

.selectedMethod {
    font-weight: bold;
    color: var(--color1);
  }
