* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
    --backgroundColor: #00ADEEFF;
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;

  }
  .AddAdveritsement{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    height: 100%;
  }
  html[dir="rtl"] .AddAdveritsement {
    right: auto;  
    left: 0;   
  }
  .AddAdveritsement .name_header{
    height: 70px;
    line-height: 70px;
    color: white;
    background-image: var(--backgroundGradientBorder);

}
.AddAdveritsement .name_header i{
    padding: 0 2%;
}

.icon{
  height: 70px;
  line-height: 70px;
  color: white;
  width: 100%;
}
.icon .i{
  padding: 0 2%;
  border-radius: 50%;
  cursor: pointer;
}

  .Add_img .add{
    background-color: white;
    height: 300px;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    margin: auto;
    padding: 2%;
    border: 2px solid var(--backgroundColor);
    display: block;
  }
  .Add_img .add .upload-placeholder{
    text-align: center;
    margin: auto;
    color: #777;
  }
  .Add_img .add .upload-placeholder i{
    text-align: center;
    margin: auto;
    color: #777;
    font-size: 50px;
  }
  .Add_img .add .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    display: block; 
}
  .Add_img .title2{
    margin-top: 5%;
    margin-bottom: 0%;
    padding: 0;
}
.title{
    margin: 20px 0 0 0;
}
.single{
  width: 100%;
  height: 100%;
}
  .Add_img .add .imgs{
    height: 120px;
    object-fit: cover;
    border-radius: 10px;
  }


   .divll input{
    outline: none;
    padding: 2%;
    height: 40px;
    line-height: 40px;
    border: 0;
    border-radius: 5px;
    width: 100%;

  }
  
  .check{
    margin: 5% 0;
    width: 100%;

  
}
  .button2{
    padding: 1%;
    border-radius: 5px;
    border: 0;
    color: white;
    background-color: var(--color1);
    height: 40px;
    line-height: 40px;
  }


  .box .heade{
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0px 7px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    

}
.box .heade i{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    transform: rotate(90deg);

}

.box .header span{
    color: #777;
    z-index: 2;
    display: block;
}

.box_AddCompany .cat{
    padding: 5px 7px 0 10px;
    background-color: white;
    z-index: 3;
    border-radius: 5px;
    top: 10px;
    position: absolute;
    width: 100%;
}
.box_AddCompany .cat p{
    line-height: 0.5;
    padding: 5% 0;
    margin: 0;
    border-bottom: 1px solid #777;
}
.box_AddCompany .cat p:hover{
    color: var(--backgroundColor);
    cursor: pointer;
}


.box_AddCompany .cat2{
    padding: 5px 7px 0 10px;
    background-color: white;
    z-index: 3;
    border-radius: 5px;
    top: 10px;
    position: absolute;
    width: 100%;
    height: 300px;
    overflow-y: auto;
}
.box_AddCompany .cat2 p{
    /* line-height: 0.5;
    padding: 5% 0;
    margin: 0; */
    border-bottom: 1px solid #777;
}
.box_AddCompany .cat2 p:hover{
    color: var(--backgroundColor);
    cursor: pointer;
}
.slect_vendor{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  border-bottom: 1px solid #777;
}
.slect_vendor:hover{
  background-color: rgb(196, 194, 194);
  cursor: pointer;
}
.slect_vendor img{
  width: 40px;
  height: 40px;
  border-radius: 3px;
  object-fit: cover;
}

.error,
.error{
    z-index: 2;
    font-size: 12px;
    font-weight: 600;
    color: red;
    float: left;
    margin-bottom: 30px;
    padding: 0;
    margin-bottom: -9px;
    text-align: start;
    display: flex;
    justify-content: end;
  }
  .popup-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: var(--backgroundGradientMain); 
    color: white;
    padding: 20px 40px;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    z-index: 1000;
    /* opacity: 0; */
    animation: fadeInOut 3.5s ease-in-out; 
    background-color: #5356FF;
}

.popup-conten {
    display: flex;
    align-items: center;
    gap: 15px; 
}

