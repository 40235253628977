* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
    --backgroundColor: #00ADEEFF;
    --dropdownBackgroundColor: #fff;
    --dropdownTextColor: #333;
    --dropdownHoverColor: var(--backgroundColor);
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;
  }
  
  .Profile {
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    min-height: 100%;
  }
  
  html[dir="rtl"] .Profile {
    right: auto;
    left: 0;
  }
  
  .profile-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 70px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .profile-image {
    width: 30%;
    height: 230px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #378CE7;

  }
  .profile-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    border: 1px solid #3498db;
  }
  
  .profile-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px; /* إضافة مسافة بين الحقول */
    width: 100%;
  }
  
  .profile-info input {
    flex: 1 1 48%; /* يجعل عرض الحقول حوالي 48% ليتناسب اثنين بجانب بعض */
    padding: 10px;
    margin: 10px 0;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .profile-info input:nth-child(odd) {
    margin-right: auto;
  }
  
  .profile-info input:nth-child(even) {
    margin-left: auto;
  }
  
  .save-button {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 60%;
  }
  
  .save-button:hover {
    background-color: #2980b9;
  }

  .box_AddCompany{
    width: 50%;
  }
  .Profile .upload-placeholder{
    width: 100%;
    height: 270px;
    border-radius: 10px;
    background-color: white;
    text-align: center;
    cursor: pointer;
  }
  .upload-placeholder p{
    padding-top: 10%;
  }
  .Profile .image-preview img{
    width: 100%;
    height: 270px;
  }

  .profile-info .form-group {
    width: 48%; /* عرض الحقول ليكون اثنين بجانب بعض */
    margin-bottom: 20px;
  }
  
  .profile-info label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
  
  .profile-info input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  @media (max-width: 768px) {
    .profile-info .form-group {
      width: 100%; /* عرض الحقول بالكامل في الشاشات الصغيرة */
    }
  }
  
  
  @media (max-width: 768px) {
    .profile-info input {
      flex: 1 1 100%; /* في الشاشات الصغيرة، يجعل الحقول تأخذ العرض بالكامل */
    }
    .profile-card {
      max-width: 100%; /* في الشاشات الصغيرة، يجعل النموذج يأخذ العرض الكامل */
      padding: 15px;
    }
  }