
.Orders_border{
    min-height: 50px;
    cursor: none;
}

.Orders_border input{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

/* تنسيقات خاصة بالقائمة المنسدلة */
.select-coupons {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    color: #333;
    font-size: 16px;
  }
  
  .select-coupons option {
    padding: 10px;
    background-color: #fff;
    color: #333;
  }
