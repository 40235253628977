* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #2E3191FF, #00ADEEFF);
    --backgroundColor: #00ADEEFF;
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;

    
  }
.Favorites{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    min-height: 100%;
  }
  html[dir="rtl"] .Favorites {
    right: auto;  
    left: 0;   
  }
.Favorites .name_header{
    height: 70px;
    line-height: 70px;
    color: white;
    background-image: var(--backgroundGradientBorder);

}
.Favorites .name_header i{
    padding: 0 2%;
}
.Favorites .Pending{
    padding: 0 2%;
    background-color: white;
    height: 50px;
    line-height: 50px;
    display: flex;
    margin: 1% auto;
    border-radius: 10px;
}
.Favorites .Pending p{
    padding: 0 2%;
    margin: 0;
}
.Favorites .Pending p:hover{
    color: var(--backgroundColor);
    cursor: pointer;
}
.Favorites .sarsh{
    color: var(--backgroundColor);
    cursor: pointer;

}
.Favorites .sarsh input{
    width: 40%;
    outline: none;
    padding: 0.8% 1%;
    border-radius: 10px;
    margin: 0;
}
.Favorites .Recent{
    padding:2% 0;

}
.Favorites .Orders{
    padding:1% 2%;
    background-color: white;
    margin: 1% 0;
    border-radius: 10px;
    height: 100%;
}
.Favorites .Orders .Orders_border{
    border-bottom: 1px solid #777;
    height: 40px;
    line-height: 40px;
    cursor: pointer;

}
.Favorites .Orders .Orders_border p{
    text-align: center;
    margin: auto;

}
.Favorites .Orders .Orders_border3{
    border-top: 1px solid #777;
    height: 70px;
    line-height: 70px;
    background-color: rgba(0, 174, 238, 0.227);
}

.ProductsWrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
    margin: 0;
    padding: 0;
}

.ProductsWrapper.active {
    max-height: 500px; 
    transition: max-height 0.5s ease-in, padding 0.5s ease-in;
    padding: 10px 15px; 
}
.image_Favorites{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}