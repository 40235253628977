

.App{
  display: flex;
  width: 100%;
  height: 100vh;
}
.App .link{
  display: flex;
  width: 100%;
}

body{
  font-size: clamp(0.5rem, 1vw, 2rem);
}
h1{
  font-size: clamp(2rem, 5vw, 4rem);
}
.font-size1{
  font-size: clamp(0.5rem, 1.5vw, 1rem);
}
.font-size2{
  font-size: clamp(1.2rem, 2vw, 3rem);
}
.font-size3{
  font-size: clamp(1.2rem, 2vw, 3rem);
} 


.custom-btn {
  margin: 0 5px;
  border-radius: 0;
}

body[dir='rtl'] .btn-group {
  direction: rtl;
}

body[dir='rtl'] .btn-group .btn {
  margin: 0 2px;
}
/* body[dir='ltr'] .Sidebar{
  left: 0;
} */

.back {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 6;
  background-color: #378CE7;

}

.back h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}
.media p {
  display: none;
}
.media h6 {
  display: none;
}

@media(max-width: 992px){
.media{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 7;
  background-color: #378CE7;
}
.media p {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  display: block;
}
.media h6 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  display: block;
}
}