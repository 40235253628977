* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
    --backgroundColor: #00ADEEFF;
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;

  }
.Customers{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    min-height: 100%;
}
  html[dir="rtl"] .Customers {
    right: auto;  
    left: 0;   
  }
  .Customers .name_header{
    height: 70px;
    line-height: 70px;
    color: white;
    background-image: var(--backgroundGradientBorder);

}
.Customers .name_header i{
    padding: 0 2%;
}
.Customers .Pending{
    padding: 0 2%;
    background-color: white;
    height: 50px;
    line-height: 50px;
    display: flex;
    margin: 1% auto;
    border-radius: 10px;
}
.Customers .Pending p{
    padding: 0 2%;
    margin: 0;
}
.Customers .Pending p:hover{
    color: var(--backgroundColor);
    cursor: pointer;
}
.Customers .sarsh{
    color: var(--backgroundColor);
    cursor: pointer;

}
.Customers .sarsh input{
    width: 40%;
    outline: none;
    padding: 0.8% 1%;
    border-radius: 10px;
    margin: 0;

}
.Customers .Recent{
    padding:2% 0;

}
.Orders{
    padding:1% 2%;
    background-color: white;
    margin: 1% 0;
    border-radius: 10px;
    height: 100%;
}
.Orders .Orders_border{
    border-bottom: 1px solid #777;
    height: 60px;
    line-height: 60px;
    cursor: default;

}

.Orders .Orders_border p{
    text-align: center;
    margin: auto;
    line-height: 1.4;

}
.Orders_border .aa {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
}

.Orders_border .aa img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 2%;
}

.Orders_border .aa div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    margin: 0;
    width: 100%;
}

.Orders_border .aa div .p {
    margin: 0px;
    padding: 2%;
    display: block;
    line-height: 100%;
}