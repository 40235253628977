.loader-container {
  position: fixed; /* يجعل الـ loader يظهر فوق كل شيء */
  top: 50%; /* يضع الـ loader في منتصف الشاشة عموديًا */
  left: 50%; /* يضع الـ loader في منتصف الشاشة أفقيًا */
  transform: translate(-50%, -50%); /* ينقل الـ loader إلى المنتصف بالضبط */
  z-index: 2; /* يضمن أن يظهر الـ loader فوق كل شيء */
  background-color: rgba(255, 255, 255, 0.8); /* لون الخلفية مع بعض الشفافية */
  width: 100%; /* يغطي كامل العرض */
  height: 100%; /* يغطي كامل الارتفاع */
  display: flex;
  justify-content: center;
  align-items: center;
}