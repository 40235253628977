* {
    box-sizing: border-box;
    margin: 0;
    padding: 0; 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
}
:root{
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF , #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF , #2E3191FF);
    --fontSizefoot: 30px;
    --fontSizemedia1: 25px;
    --fontSizemedia2: 20px;
    --fontSizemedia3: 18px;
    --fontSizemedia4: 16px;
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;
}

.Header {
    width: 100%;
    position: fixed;
    z-index: 5; 
    top: 0px;
    margin: 0;
    height: 70px;
    line-height: 70px;
    left: 0;
}
.naveBar{
    width: 100%;
    background-color: var(--color1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
}
.naveBar .inpute_search{
    width: 40%;
    height: 40px;
    line-height: 50px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    padding: 0 10px;

}
.logo_header img{
 width: 120px;
}
.naveBar .inpute_search input{
    width: 100%;
    height: 100%;
}
.group_profile{
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: space-between;

}
.group_profile .img_name{
    display: flex;
    align-items: center;
}
.group_profile .img_name div{
    display: flex;
    align-items: center;
}
.group_profile .img_name div img{
    width: 50px;
    height: 50px;
    color: var(--color1);
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    background-color: #f5f5f5;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0 5px;
}
.group_profile .img_name div img:hover{
    background-color: #e0e0e0;
    transform: scale(1.1);
}


.group_profile .img_name div span{
    color: white;
}

.Header .language .slectList {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.Header .language .slectList .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    position: absolute;
}

.Header .language .slectList i{
    margin-left: 5%;
    color: white;
    width: 40px;
    text-align: center;
    cursor: pointer;

}

.Header .language .slectList p{
    margin: 0;
    padding: 0;
    color: white;
    font-weight: 400;
}
.Header .language .slectList .languageP {
    font-weight: 400;
}

.Header .containers img {
    width: 45px;
    height: 27px;
}

.language {
    position: relative;
}


.lest P {
    border-bottom: 1px solid #777;
    font-weight: 300;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0;
}

.lest img {
    border-bottom: 1px solid #777;
    cursor: pointer;
    padding: 10px;
    width: 70px;
    height: 50px;

}

.lest {
    position: absolute;
    top: 50px; 
    right: 7px;
    z-index: 3;
    background-color: white;
    border: 1px solid #ccc;
    width: 80px;
    margin: auto;
    text-align: center;
    border-radius: 5px;
    height: fit-content;
}

.noActiv_p {
    transition: transform 0.3s ease;
}

.activ_p {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}

/* زر الإشعارات */
.ntofcation {
    width: 50px;
    height: 50px;
    color: var(--color1);
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    background-color: #f5f5f5;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.ntofcation:hover {
    background-color: #e0e0e0;
    transform: scale(1.1);
}

/* النقطة الخضراء للإشعارات الجديدة */
.notification-dot {
    position: absolute;
    top: -5px;
    right: -5px;
    height: 15px;
    width: 15px;
    background-color: green;
    border-radius: 50%;
    border: 2px solid white;
    color: white;
    font-size: 10px;
    text-align: center;
    line-height: 13px;
    font-weight: bold;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

/* قائمة الإشعارات */
.notifications-dropdown {
    position: absolute;
    top: 68px;
    left: 4%;
    width: 300px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.15);
    z-index: 1000;
}
/* عندما يكون اتجاه الصفحة LTR */
html[dir="ltr"] .notifications-dropdown {
    left: auto;  /* تعطيل اليسار */
    right: 4%;   /* وضع القائمة على اليمين */
}

.notifications-dropdown.show {
    opacity: 1;
    visibility: visible;
    max-height: 400px;
}

.notifications-dropdown ul {
    list-style: none;
    padding: 10px;
    margin: 0;
}

.notifications-dropdown ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.notifications-dropdown ul li:hover {
    background-color: #f9f9f9;
}

.notifications-dropdown ul li .notif-text {
    font-size: 14px;
    color: #333;
    flex-grow: 1;
}

.notifications-dropdown ul li .notif-time {
    font-size: 12px;
    color: #888;
    text-align: right;
    margin-left: 10px;
}

/* إذا لم تكن هناك إشعارات */
.notifications-dropdown .no-notifications {
    text-align: center;
    padding: 20px;
    color: #aaa;
    font-size: 14px;
}

/* خلفية عند فتح القائمة */
.background {
    background-color: rgba(236, 229, 229, 0.533);
}
.notification-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.4;
  }
  
  .notification-text {
    font-size: 14px;
    color: #333;
    margin: 0;
    line-height: 1.3;

  }
  
  .notification-date {
    font-size: 10px;
    color: #888; /* لون أفتح للتاريخ */
    margin-top: 4px; /* مسافة صغيرة أعلى التاريخ */
    text-align: left; /* محاذاة التاريخ لليسار */
  }
  