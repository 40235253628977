* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #2E3191FF, #00ADEEFF);
    --backgroundColor: #00ADEEFF;
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;

    
  }
.OrdersManagement{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    min-height: 100%;
  }
  html[dir="rtl"] .OrdersManagement {
    right: auto;  
    left: 0;   
  }
.OrdersManagement .name_header{
    height: 70px;
    line-height: 70px;
    color: white;
    background-image: var(--backgroundGradientBorder);

}
/* icon_Invoice */
.icon_Invoice{
    position: absolute;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 35px;
    cursor: pointer;
    color: white;
    background-image: var(--backgroundGradientMain);
    margin-top: 8px;
    left: 2%;
    transition: transform 0.3s ease, background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.icon_Invoice:hover{
background-color: #e0e0e0;
transform: scale(1.1);
}



html[dir="rtl"] .icon_Invoice {
    left: auto;  
    right: 2%;   
  }
.OrdersManagement .name_header i{
    padding: 0 2%;
}
.OrdersManagement .Pending{
    padding: 0 2%;
    background-color: white;
    height: 50px;
    line-height: 50px;
    display: flex;
    margin: 1% auto;
    border-radius: 10px;
}
.OrdersManagement .Pending p{
    padding: 0 2%;
    margin: 0;
}
.product_image{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.OrdersManagement .Pending p:hover{
    color: var(--backgroundColor);
    cursor: pointer;
}
.OrdersManagement .sarsh{
    color: var(--backgroundColor);
    cursor: pointer;

}
.OrdersManagement .sarsh input{
    width: 40%;
    outline: none;
    padding: 0.8% 1%;
    border-radius: 10px;
    margin: 0;
}
.OrdersManagement .Recent{
    padding:2% 0;

}

.OrdersManagement .Orders{
    padding:1% 2%;
    background-color: white;
    margin: 1% 0;
    border-radius: 10px;
    height: 100%;
}
.OrdersManagement .Orders .Orders_border{
    border-bottom: 1px solid #777;
    min-height: 40px;
    line-height: 40px;
    cursor: default;



}
.Orders_border.hover:hover {
    background-color: #d0cfcf;
    cursor: pointer;


}
.OrdersManagement .Orders .Orders_border p{
    text-align: center;
    margin: auto;


}

.Orders .Orders_border2{
    border-bottom: 1px solid #777;
    min-height: 40px;
    line-height: 40px;
    background-color: rgba(0, 174, 238, 0.395);

}

.Orders .Orders_border2 p{
    padding: 5px 0;
    margin: 0;

}
.OrdersManagement .Orders .Orders_border3{
    border-top: 1px solid #777;
    min-height: 70px;
    line-height: 70px;
    background-color: rgba(0, 174, 238, 0.227);

}

.ProductsWrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out, padding 0.5s ease-out;
    margin: 0;
    padding: 0;
}

.ProductsWrapper.active {
    max-height: 500px; 
    transition: max-height 0.5s ease-in, padding 0.5s ease-in;
    padding: 10px 15px; 
}