* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
    --backgroundColor: #00ADEEFF;
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;

  }
.DataCustomers{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    height: 100%;
  }
  html[dir="rtl"] .DataCustomers {
    right: auto;  
    left: 0;   
  }
.DataCustomers .name_header{
    height: 70px;
    line-height: 70px;
    color: white;
    background-image: var(--backgroundGradientBorder);

}
.DataCustomers .name_header i{
    padding: 0 2%;
}
.DataCustomers .Pending{
    padding: 0 2%;
    background-color: white;
    min-height: 50px;
    display: flex;
    margin: 1% auto;
    border-radius: 10px;
    position: relative;
}

.DataCustomers .Pending .box1{
height: 100%;
}
.DataCustomers .Pending .box1 div{
    display: flex;
    align-items: center;
}
.DataCustomers .Pending .box1 img{
    width: 50PX;
    height: 50PX;
    border-radius: 50%;
    object-fit: cover;
}
.DataCustomers .Pending .box1 p{
    margin: 0;
    padding: 0 2%;
}
.custmr_Points{
    color: rgb(184, 51, 44);
    margin: auto;
    text-align: center;
}
.DataCustomers .Pending .box2 .cont{
    border-right: 1px solid var(--backgroundColor);
    border-left: 1px solid var(--backgroundColor);
    display: flex;
    padding: 2%;
}
.DataCustomers .Pending .box2 .cont div{
    width: 50%;
}
.DataCustomers .Pending .box2 .cont div p{
    padding: 1% 0;
}
.DataCustomers .Pending .box2 .cont div:first-child{
    color: #777;
}
.DataCustomers .Pending .box3 .cont2{
    display: flex;
    justify-content: space-around;
}
.DataCustomers .Pending .box3 .cont2 div{
    text-align: center;
}
.DataCustomers .Pending .box3 .cont2 div span{
    color: red;
}
.DataCustomers .Pending .box3 .cont2 div p{
    color: #777;
}
.DataCustomers .Pending .title1,
.DataCustomers .Pending .title2{

    color: var(--backgroundColor);
    display: block;
    margin-bottom: 30px;
    padding: 5px 3%;
    margin: 0;
}
.DataCustomers .Pending .title2{
    position: absolute;
    top: 0;
}

.DataCustomers .sarsh{
    color: var(--backgroundColor);
    cursor: pointer;

}
.DataCustomers .sarsh input{
    width: 40%;
    outline: none;
    padding: 0.8% 1%;
    border-radius: 10px;
    margin: 0;

}
.DataCustomers .Recent{
    padding:2% 0;

}
.DataCustomers .Orders{
    padding:1% 2%;
    background-color: white;
    margin: 1% 0;
    border-radius: 10px;
    height: 100%;
}
.DataCustomers .Orders .Orders_border{
    border-bottom: 1px solid #777;
    height: 40px;
    line-height: 40px;

}

.DataCustomers .Orders .Orders_border3{
    height: 70px;
    line-height: 70px;
    background-color: rgba(0, 174, 238, 0.227);
}
