* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
    --backgroundColor: #00ADEEFF;
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */

  }
  .AddAllCoupon{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    min-height: 100%;
  }
  html[dir="rtl"] .AddAllCoupon {
    right: auto;  
    left: 0;   
  }

  form .Coupon {
    display: flex;
    flex-direction: column; 
    width: 50%;
    margin: 20px 0;
  }
  
  form .Coupon input {
    width: 100%; 
    padding: 10px;
    margin-top: 5px; 
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  form .button {
    width: 40%;
    height: 40px;
    background-color: var(--color2);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  form .button:hover {
    background-color: var(--color1);
  }
  .Orders{
    background-color: white;
  }
  .div_Transactions{
    border-bottom: 1px solid #777;
    height: 50px;
    line-height: 50px;
  }
  .div_Transactions p{
    margin: 0;
  }