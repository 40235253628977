* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
    --backgroundColor: #00ADEEFF;
    --dropdownBackgroundColor: #fff;
    --dropdownTextColor: #333;
    --dropdownHoverColor: var(--backgroundColor);
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;
  }
  .ProductsList {
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    min-height: 100%;
  }
  html[dir="rtl"] .ProductsList {
    right: auto;  
    left: 0;   
  }
  .ProductsList .name_header {
    height: 70px;
    line-height: 70px;
    color: white;
    background-image: var(--backgroundGradientBorder);
  }
  .ProductsList .name_header i {
    padding: 0 2%;
  }
  
  .ProductsList .Pending {
    padding: 0 2%;
    background-color: white;
    height: 50px;
    line-height: 50px;
    display: flex;
    margin: 1% auto;
    border-radius: 10px;
  }
  
  .ProductsList .Pending p {
    padding: 0 2%;
    margin: 0;
  }
  
  .ProductsList .Pending p:hover {
    color: var(--backgroundColor);
    cursor: pointer;
  }

  .ProductsList .sarsh {
    color: var(--backgroundColor);
  }
  .ProductsList .sarsh div{
    display: flex;
    align-items: center;
    color: var(--backgroundColor);
    width: 40%;

  }
  .ProductsList .sarsh div:last-child{
    width: 50%;

  }
  .ProductsList div button{
    margin: 0 2%;
    width: 30%;
  }
  .ProductsList .sarsh div span{
    width: 40%;
    text-align: center;
  }
  .ProductsList .sarsh input {
    width: 100%;
    outline: none;
    padding: 0.8% 1%;
    border-radius: 10px;
    margin: 0;
  }
  
  .ProductsList .Recent {
    padding: 2% 0;
  }
  
  .ProductsList .Orders {
    padding: 1% 2%;
    background-color: white;
    margin: 1% 0;
    border-radius: 10px;
    height: 100%;
    position: relative;
  }
  
  .ProductsList .Orders .Orders_border {
    border-bottom: 1px solid #777;
    height: fit-content;
    cursor: default;
  }
  
  .ProductsList .Orders .Orders_border p {
    margin: 0;
    padding: 0;
  }
  .ProductsList .Orders .Orders_border p span{
    line-height: 1.3;
  }
  
  .ProductsList .Orders .Orders_border img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 2%;
    object-fit: cover;
  }
  
  .span_icon {
    margin: 7%;
    color: var(--backgroundColor);
    cursor: pointer;
  }
  
  .box_add {
    padding: 0;
    position: relative;
  }
  
  .box_add .heade {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    border-radius: 7px;
    background-color: var(--backgroundColor);
    color: white;
  }
  
  .box_add .heade i {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    transform: rotate(90deg);
  }
  
  .box_add .heade span {
    color: white;
    z-index: 2;
    display: block;
  }
  
  .box_add .cat2 {
    padding: 10px 0;
    background-color: var(--dropdownBackgroundColor);
    z-index: 3;
    border-radius: 5px;
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--dropdownBorderColor);
    height: fit-content;
  }
  
  .box_add .cat2 p {
    margin: 0;
    padding: 0;
    color: var(--dropdownTextColor);
    border-bottom: 1px solid var(--dropdownBorderColor);
    text-align: center;
  }
  
  .box_add .cat2 p:last-child {
    border-bottom: none;
  }
  
  .box_add .cat2 p:hover {
    background-color: var(--dropdownHoverColor);
    color: white;
    cursor: pointer;
  }