* {
    box-sizing: border-box;
    margin: 0;
    padding: 0; 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root{
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF , #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF , #2E3191FF);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;
  }
.Register{
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #378CE7;


}
.Register .login_activ{
  height: 100%;
   position: absolute;
   top: 0;
   background-color: white;
}
.Register .cont{
  background-color: white;
  position: relative;
  z-index: 5;   
  background-color: #378CE7;
  min-height: 100%;
  left: 0;
  margin: 0;
  padding: 0;

}
.container_img{
  margin: auto;
  text-align: center;
  padding: 3% 0;
  background-color: white;
  z-index: 3;

}

.container_img img{
  height: 100%;
  width: 90%;
  margin: auto;
  object-fit: cover;
}
.Register .cont .icon{
  justify-content: flex-end;
  display: flex;
}
.Register .cont .icon i{
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-image: var(--backgroundGradientMain);
  border-radius: 50%;
  margin: 2%;
  cursor: pointer;
  display: block;
  text-align: center;
  color: white;
}
.Register .logo_dash{
  width: 100%;
  text-align: center;
  padding: 1% 0;
}
.Register .logo_dash img{
  width: 25%;
}
.Register .createAccount{
  width: 100%;
  text-align: start;
  padding: 0% 3%;
  display: flex;
  justify-content: space-between;
}
.Register .createAccount img{
  height: 40px;
  object-fit: cover;
}
.Register .createAccount p{
  color: white;
}

.signup{
width: 100%;
min-height: 100%;
margin: 0;
padding: 0;

}
.container_otp {
position: fixed;
top: 10%;
width: 100%;
z-index: 10;
}
/* :dir(rtl) .container_otp {
right: 50%;
} */

/* :dir(ltr) .container_otp {
} */
.container_otp .cont .logo_dash{
background-image: var(--backgroundGradientMain);

}
.container_otp .cont{
background-color: white;
border-radius: 10px;
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.19);
padding: 5px 0;
margin:  auto;


}
  .Register .logo_dash p:last-child{
    margin: 4% 0 0 0;
    color: white;
  }
  .Register .div1,
  .Register .div2,
  .Register .div3,
  .Register .div4,
  .Register .div5,
  .Register .div_lest,
  .Register .div6,
  .Register .div7,
  .Register .div8,
  .Register .div9,
  .Register .div10,
  .Register .div11{
    height: 40px;
    line-height: 40px;
    margin: auto;
    border-radius: 10px;
    width: 80%;
  }
  .Register .div1{
    width: 80%;
    text-align: center;
    margin: 2% auto;
    color: #00ADEEFF;
  }
  .email input{
    background-color: rgb(233, 231, 231);
    border: 1px solid #777;
  }
  .Register .div10{
    width: 80%;
    padding: 0% 3%;
    margin:0 auto;
    background-image: var(--backgroundGradientMain);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .Register .div10 p{
    width: 45%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin:  0;
    padding: 0;
    border-radius: 10px;
    cursor: pointer;
    color: white;
  }
  .Register .div10 p.active{
    background-color: whitesmoke;
    color: black;
  }
  .Register .div3,
  .Register .div4{
    width: 90%;
    height: 50px;
    background-color: #357AE8;
    background-color: white;
    margin-top: 3%;
    border-radius: 5px;
  }
  .Register input{
    width: 100%;
    height: 100%;
    border: 0;
    outline: none;
    margin: 0;
    padding: 0 2%;

   
  }
  .Register .div5{
    text-align: end;
    cursor: pointer;
  }
  .Register .div_lest{
    height: 120px;
    margin: 0 auto;
  }
  .Register .div_lest p{
    color: white;
    margin-bottom: -7px;
    padding: 0;
  }
  .Register .div6{
    background-color: rgb(202, 198, 198);
    text-align: center;
    color: #777;
    cursor: pointer;

  }
  .Register .div6 button{
    width: 100%;
    height: 100%;
    outline: none;
    border: 0;
    border-radius: 5px;
  }
  

  .Register .div7{
    text-align: center;
    color: #00ADEEFF;
    font-weight: 300;
    margin: 2% auto;
  }
  .Register .div8,
  .Register .div9,
  .Register .div10,
  .Register .div11{
    border: 1px solid #777;
    margin-bottom: 2%;
    display: flex;
    justify-content: space-between;
    padding: 0 3%;
    cursor: pointer;
  }

  .Register .div13,
  .Register .div14,
  .Register .div15{
    width: 80%;
    margin: 3% auto;
  }
  .Register .div13 p{
    color: #777;
    margin: 0 auto;
  }
  .Register .div15 p{
    margin: 0;
  }
  .Register .div15 input{
    outline: none;
    border: 1px solid black;
    line-height: 100px;
    background-color: #4285F4;
  }

  .valid{
    background-image: var(--backgroundGradientMain);
    color: white;
    border-radius: 10px;
  }

  .otp-input {
    font-size: 24px;
    letter-spacing: 10px; 
    text-align: center; 
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc; 
    border-radius: 5px; 
    outline: none; 
    background-color: #f9f9f9; 
}

.otp-input::placeholder {
    color: #ccc; 
    letter-spacing: 10px;
}
.error-message{
  color: red;
  margin: auto;
  line-height: 1.1;
  width: 80%;
  padding: 3px 0;
  margin: 60px auto 0px;

}
.custom-google-button {
  background-color: #4285F4 !important; 
  color: white !important; 
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 5px !important; 
  font-size: 16px !important; 
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

.custom-google-button:hover {
  background-color: #357AE8 !important; 
}

.custom-google-button:disabled {
  background-color: #cccccc !important; 
  cursor: not-allowed !important; 
}


.popup_log {
  position: fixed;
  top: 30%;
  right: 35%;
  padding: 20px 20px;
  border-radius: 5px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  opacity: 1;
  transform: translateY(0);
  z-index: 12;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 30%;
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.19);

}
.popup_log.hide {
  opacity: 0;
  transform: translateY(-20px);
}

.popup_log-icon {
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.popup-icon .fas {
  font-size: 20px;
}


.div1 select,
.Security .div1 input,
.div1 input{
  width: 100%;
  outline: none;
  border: 1px solid var(--backgroundColor);
  border-radius: 5px;
  padding: 0 1%;
}
select{
  border: 1px solid var(--backgroundColor);
}
.div1 textarea{
  width: 100%;
  outline: none;
  border: 1px solid var(--backgroundColor);
  border-radius: 3px;
  min-height: 170px;
  line-height: 40px;
  padding: 1%;
  line-height: 1.5;
}

.Add_image{
  width: 60%;
  border: 1px solid var(--backgroundColor);
  border-radius: 10px;
  height: 250px;
  padding: 0;
  margin: 0;
  display: block;
  position: relative;
  background-color: white;
}

.Add_image img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  margin: 0;
  padding: 0;
  display: block;

}
.Add_image .adds{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  padding-bottom: 0;
  text-align: center;
  margin: auto;
  color: var(--backgroundColor);
  overflow: hidden;
  z-index: -1;
}
.Add_image .adds .upload-placeholder{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding-bottom: 0;
  text-align: center;
  margin: auto;
  color: var(--backgroundColor);
  margin-top: 30%;
}


.Register .div12{
  width: 90%;
  margin: 3% auto 0;
}
.div12 textarea{
  width: 100%;
  min-height: 150px;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  outline: none;
}

.continue-button{
  background-color: rgb(212, 208, 208);
  color: black;
  height: 100%;
}
.display_none{
  display: none;
}

@media (min-width: 567px) {
  .container_otp {
    right: 0.5%;
    }
}
@media (max-width: 567px) {
  .Register .createAccount {
    display: none; /* إخفاء العنصر */
    background-color: #42f477; /* (اختياري) تغيير لون الخلفية */
  }
  .div12 textarea{
    width: 100%;
    min-height: 100px;
  }
  .Register .div1,
  .Register .div2,
  .Register .div3,
  .Register .div4,
  .Register .div5,
  .Register .div_lest,
  .Register .div6,
  .Register .div7,
  .Register .div8,
  .Register .div9,
  .Register .div10,
  .Register .div11{
    height: 40px;
    line-height: 40px;

  }
  .Add_img{
    width: 70%;
    height: 200px;
  }
  .container_img{
    display: none;
  
  }
  .flex_reverse{
    display: flex;
    flex-direction: column-reverse;
  }
  .Register .div6{
    margin: 60px auto 20px;

  }
  .display_none{
    display: block;
  }
  .error-message{
    margin: 60px auto -50px;

  }
}
.input-errors {
  border: 2px solid red;
  outline: none;
}
