* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
    --backgroundColor: #00ADEEFF;
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;

  }
.Adveritsement{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    min-height: 100%;
  }
  html[dir="rtl"] .Adveritsement {
    right: auto;  
    left: 0;   
  }
  
.Adveritsement .name_header{
    height: 70px;
    line-height: 70px;
    color: white;
    background-image: var(--backgroundGradientBorder);

}
.Adveritsement .name_header i{
    padding: 0 2%;
}
.Adveritsement .sarsh{
    margin-top: 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Adveritsement .sarsh .AddCat{
    width: 14%;
    background-color: var(--backgroundColor);
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;

}
.Adveritsement .sarsh .AddCat span{
    margin: 0;
    padding: 0;

}
.Adveritsement .sarsh .AddCat span:last-child{
    margin: 0;
    padding: 0;
    color: white;

}
.Adveritsement .sarsh .AddCat span:first-child{
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: white;
    border-radius: 50%;
    margin: 5%;
    display: block;
}
.Adveritsement .sarsh input{
    width: 40%;
    outline: none;
    padding: 0.8% 1%;
    border-radius: 10px;
    margin: 0;

}
.Adveritsement .Recent{
    padding:2% 0;

}
.Adveritsement .Orders{
    padding:1% 2%;
    background-color: white;
    margin: 1% 0;
    border-radius: 10px;
    height: 100%;
    cursor: default;
}
.Adveritsement .Orders .Orders_border{
    border-bottom: 1px solid #777;
    min-height: 150px;

}
.Adveritsement .Orders .Orders_border .ico {
    color: var(--backgroundColor);
    cursor: pointer;
}

.Adveritsement .Orders .Orders_border p{
    text-align: center;
    margin: auto;
}
.Adveritsement .Orders .Orders_border p img{
    width: 60%;
    height: 120px;
    border-radius: 5PX;
    display: block;
    object-fit: cover;
    text-align: center;
    margin: auto;
    object-fit: cover;
    padding: 2PX 0;

}