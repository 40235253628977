


.CustomerReports h5 {
  margin: 20px 0;
  color:#5356FF ;
}

.CustomerReports{
  width: 83%;
  position: absolute;
  right: 0;
  background-color: #DFF5FF;
  min-height: 100%;

}
html[dir="rtl"] .CustomerReports {
  right: auto;  
  left: 0;   
}

/* رسم البياني */
.chart-container {
  margin: 40px 0;
}

.CustomerReports .ul {
  list-style-type: none;
  padding: 0;
}

.CustomerReports .ul p {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.1rem;
}

.CustomerReports p {
  text-align: center;
}

