.invoice {
  width: 83%;
  font-family: 'Arial', sans-serif;
  background-color: #DFF5FF;
  margin-top: 70px;
  position: absolute;
}

/* عند الكتابة من اليمين إلى اليسار (للغة العربية) */
html[dir="rtl"] .invoice {
  left: 0;    /* تثبيت الفاتورة على اليسار */
  right: auto;
}

/* عند الكتابة من اليسار إلى اليمين (للغة الإنجليزية) */
html[dir="ltr"] .invoice {
  right: 0;   /* تثبيت الفاتورة على اليمين */
  left: auto;
}


.invoice_pading {
  padding: 20px;
  background-color: #DFF5FF;
  border: 1px solid #ddd;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.invoice .logo {
  width: 100px;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.product-table th, .product-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.product-image {
  width: 50px;
}

.totals {
  margin-top: 20px;
  /* text-align: right; */
}

.totals p {
  margin: 5px 0;
}

.actions {
  margin-top: 20px;
  text-align: right;
}

.action {
  display: flex;
  justify-content: space-around;
}

.action p {
  padding: 10px 20px;
  margin-left: 10px;
  color: white;
  border: none;
  cursor: pointer;
  width: 30%;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  background-color: #5356FF ;
}

.action p:hover {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.19);
}