.InventoryReports{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #DFF5FF;
    height: 100%;
    margin-top: 100px;
  }
  html[dir="rtl"] .InventoryReports {
    right: auto;  
    left: 0;   
  }