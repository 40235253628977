* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  :root {
    --backgroundGradientMain: linear-gradient(to bottom, #2E3191FF, #00ADEEFF);
    --backgroundGradientBorder: linear-gradient(to right, #00ADEEFF, #2E3191FF);
    --backgroundColor: #00ADEEFF;
    --dropdownBorderColor: rgb(242, 240, 240);
    --color1: #5356FF;
    --color2: #378CE7;
    --color3: #67C6E3;
    --color4: #DFF5FF;

  }
  .AddCategoire{
    width: 83%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color4);
    min-height: 100%;
  }
  html[dir="rtl"] .AddCategoire {
    right: auto;  
    left: 0;   
  }
  .AddCategoire .name_header{
    height: 70px;
    line-height: 70px;
    color: white;
    background-image: var(--backgroundGradientBorder);

}
.AddCategoire .name_header i{
    padding: 0 2%;
}
.AddCategoire .container .icon{
    padding: 0 2%;
    color: var(--color1);
}

.icon{
  height: 70px;
  line-height: 70px;
  color: white;
  width: 100%;
}
.icon .i{
  padding: 0 2%;
  border-radius: 50%;
  cursor: pointer;
}

  .Add_img .add{
    background-color: white;
    height: 300px;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    margin: auto;
    padding: 2%;
    border: 2px solid var(--backgroundColor);
    display: block;
  }
  .Add_img .add .upload-placeholder{
    text-align: center;
    margin: auto;
    color: #777;
  }
  .Add_img .add .upload-placeholder i{
    text-align: center;
    margin: auto;
    color: #777;
    font-size: 50px;
  }
  .Add_img .add .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    display: block; 
}
  .Add_img .title2{
    margin-top: 5%;
    margin-bottom: 0%;
    padding: 0;
}
.single{
  width: 100%;
  height: 100%;
}
  .Add_img .add .imgs{
    height: 120px;
    object-fit: cover;
    border-radius: 10px;
  }

  .container_inpot .div2,
  .container_inpot .div1{
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
    background-color: #378CE7;
  }
   .container_inpot input{
    outline: none;
    padding: 2%;
    height: 40px;
    line-height: 40px;
    border: 0;
    border-radius: 5px;
  }
  
  .check{
    margin: 5% 0;
    width: 100%;

  }
   button{
    padding: 1%;
    border-radius: 5px;
    text-align: center;
    display: block;
    border: 0;
    color: white;
    margin: auto;
    background-color: rgb(221, 65, 109);
  }



  .box_AddCompany .heade{
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0px 7px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    background-color: white;
    

}

.box_AddCompany .heade i{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    transform: rotate(90deg);

}

.box_AddCompany .header span{
    color: #777;
    z-index: 2;
    display: block;
}

.box_AddCompany{
    padding: 0;
    cursor: pointer;
    position: relative;
    
}
.box_AddCompany .cat{
    padding: 5px 7px 0 10px;
    background-color: white;
    z-index: 3;
    border-radius: 5px;
    top: 10px;
    position: absolute;
    width: 100%;
}
.box_AddCompany .cat p{
    line-height: 0.5;
    padding: 5% 0;
    margin: 0;
    border-bottom: 1px solid #777;
}
.box_AddCompany .cat p:hover{
    color: var(--backgroundColor);
    cursor: pointer;
}


.box_AddCompany .cat2{
    padding: 5px 7px 0 10px;
    background-color: white;
    z-index: 3;
    border-radius: 5px;
    top: 10px;
    position: absolute;
    width: 100%;
    height: 300px;
    overflow-y: auto;
}
.box_AddCompany .cat2 p{
    border-bottom: 1px solid #777;
}
.box_AddCompany .cat2 p:hover{
    color: var(--backgroundColor);
    cursor: pointer;
}

.error,
.error{
    z-index: 2;
    font-size: 12px;
    font-weight: 600;
    color: red;
    float: left;
    margin-bottom: 30px;
    padding: 0;
    margin-bottom: -9px;
    text-align: start;
    display: flex;
    justify-content: end;
  }